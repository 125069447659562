(function () {
  'use strict';

  class Schools {
    constructor(SchoolsRest, $cookies, $rootScope) {
      this.SchoolsRest = SchoolsRest;
      this.$cookies = $cookies;
      this.$rootScope = $rootScope;
    }

    schoolsIndex(school = {}, callback = angular.noop) {
      return this.SchoolsRest.schools().get(school,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    schoolsCreate(school = {}, callback = angular.noop) {
      return this.SchoolsRest.schools().save({school: school},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    mySchools(school = {}, callback = angular.noop) {
      return this.SchoolsRest.mySchools().get(school,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    schoolView(school = {}, callback = angular.noop) {
      return this.SchoolsRest.school().get(school,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    schoolDelete(school = {}, callback = angular.noop) {
      return this.SchoolsRest.school().remove(school,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    schoolEdit(school = {}, callback = angular.noop) {
      return this.SchoolsRest.school().update({school: school},
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }

    lomMetadata(lomMetadata = {}, callback = angular.noop) {
      return this.SchoolsRest.lomMetadata().get(lomMetadata,
        function (data) {
          return callback(data);
        },
        function (err) {
          return callback(err);
        }.bind(this)).$promise;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:Schools
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('Schools', Schools);
}());
